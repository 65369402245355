body {
	width: 100vw;
	margin: 0;
	font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow-x: hidden;
	overflow-y: overlay;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

*::-webkit-scrollbar {
	width: 4px;
	height: 4px;
	background: transparent;
}
*::-webkit-scrollbar-button {
	display: none;
	background: transparent;
}

*:active::-webkit-scrollbar-thumb,
*:focus::-webkit-scrollbar-thumb,
*:hover::-webkit-scrollbar-thumb {
	visibility: visible;
}
*::-webkit-scrollbar-thumb {
	border-radius: 0px;
	background: #707070;
	visibility: hidden;
}

*::-webkit-scrollbar-track-piece {
	background: transparent;
}
